import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, VStack, Heading, Text, Button, Flex, Image, useColorModeValue, Link, Container } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { PopupButton } from '@typeform/embed-react'
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const TeamMember = ({ name, title, image, linkedin, experience }) => (
  <Box textAlign="center" p={4}>
    <Image src={image} alt={name} borderRadius="full" boxSize="150px" mx="auto" mb={4} />
    <Heading as="h3" size="md">{name}</Heading>
    <Text>{title}</Text>
    <Link href={linkedin} isExternal color="inherit">
      <Flex align="center" justify="center" mt={2}>
        <FaLinkedin />
        <Text ml={2}>LinkedIn</Text>
      </Flex>
    </Link>
    <Text mt={2}>Experience: {experience}</Text>
  </Box>
);

const HomePage = ({ textColor }) => (
  <VStack spacing={8} textAlign="center" w="100%" maxW="container.md" px={4}>
    <Heading as="h1" size="2xl">OpenGPT</Heading>
    <Text fontSize="xl">Facilitate Research and Accelerate Development of Generative AI</Text>
    <Box>
      <PopupButton id="yiqFbYhT" style={{
        fontSize: 20,
        backgroundColor: '#1A202C',
        color: "white",
        padding: '5px 10px',
        borderRadius: "3px"
      }} className="my-button">
        Schedule Free Consultation
      </PopupButton>
    </Box>
  </VStack>
);

const TeamPage = () => (
  <Container maxW="container.xl" pt={20}>
    <Heading as="h2" size="xl" textAlign="center" mb={10}>Our Team</Heading>
    <Flex direction={["column", "row"]} justify="space-around">
      <TeamMember
        name="Sebastian Sosa"
        title="CEO"
        image="/seb.png"
        linkedin="https://www.linkedin.com/in/sebastian-sosa-918b19184/"
        experience="OpenPlugin"
      />
      <TeamMember
        name="Tim Phillips"
        title="CTO"
        image="/tim.png"
        linkedin="https://www.linkedin.com/in/timothyephillips/"
        experience="Northrop Grumman"
      />
    </Flex>
  </Container>
);

const App = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const { innerWidth, innerHeight } = window;
      const x = (clientX / innerWidth - 0.5) * 20;
      const y = (clientY / innerHeight - 0.5) * 20;
      setMousePosition({ x, y });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const textColor = useColorModeValue('gray.800', 'gray.100');

  return (
    <ChakraProvider>
      <Helmet>
        <title>OpenGPT - Generative AI Infrastructure Consulting</title>
        <meta name="description" content="OpenGPT facilitates research and accelerates development of Generative AI. Schedule a free consultation today." />
        <link rel="icon" href="/logo.png" />
      </Helmet>
      <Box
        bgImage="url('/simple_architecture.png')"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize={`auto 100%`}
        h="100vh"
        w="100vw"
        position="fixed"
        opacity={0.3}
        style={{
          transform: `translate(${mousePosition.x}%, ${mousePosition.y}%)`,
          transition: 'transform 0.1s ease-out',
        }}
      />
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        color={textColor}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1}
      >
        <Button 
          variant="ghost" 
          onClick={() => setCurrentPage('home')} 
          display="flex" 
          alignItems="center"
        >
          <Image src="/logo_trans.png" alt="OpenGPT Logo" height="50px" />
        </Button>
        <Flex align="center">
          <Button variant="ghost" p={3} D onClick={() => setCurrentPage('team')} mr={4}>
            Team
          </Button>
          <Link href="https://github.com/OpenGPTs-platform" isExternal color="inherit">
            <Flex align="center">
              <FaGithub />
              <Text ml={2}>GitHub</Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>
      <Box minH="100vh" position="relative" pt="80px">
        <Flex direction="column" align="center" justify="center" minH="calc(100vh - 80px)" color={textColor}>
          {currentPage === 'home' ? <HomePage textColor={textColor} /> : <TeamPage />}
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default App;